import React from "react";
import IMTStarter from "../../assets/LogoIMT.png";
import MSDUK from "../../assets/LogoMSDUK.png";
import WINLAB from "../../assets/LogoWINLAB.png";
import Aynergy from "../../assets/LogoAYNERGY.png";
import StationF from "../../assets/LogoSTATIONF.png";

const Partenaire = () => {
  return (
    <div name="partenaire" className="w-full my-5 bg-slate-50">
      <div className="max-w-[1250px] mx-auto px-4">
        <div className="grid grid-cols-2 md:grid-cols-5 gap-8 md:gap-20 place-items-center py-6">
          <img
            alt="IMTStarter"
            className="h-12 md:h-16 object-contain"
            src={IMTStarter}
          />
          <img
            alt="MSDUK"
            className="h-12 md:h-16 object-contain"
            src={MSDUK}
          />
          <img
            alt="WINLAB"
            className="h-12 md:h-16 object-contain"
            src={WINLAB}
          />
          <img
            alt="Aynergy"
            className="h-12 md:h-16 object-contain"
            src={Aynergy}
          />
          <img
            alt="StationF"
            className="h-12 md:h-16 object-contain col-span-2 md:col-span-1"
            src={StationF}
          />
        </div>
      </div>
    </div>
  );
};

export default Partenaire;
