import React from "react";
import "./FooterStyles.css";
import Logo from "../../assets/b-flow_logo.png";

import {
  FaFacebook,
  FaLinkedin,
  FaSearchLocation,
  FaPhone,
  FaMailBulk,
} from "react-icons/fa";
// import { MdAlternateEmail, MdPhone } from 'react-icons/md'

const Footer = () => {
  return (
    <footer className="p-8 bg-gray-800">
      <div className="max-w-[1250px] mx-auto">
        {/* Section supérieure */}
        <div className="flex flex-col items-center mb-8">
          {/* Logo */}
          <a href="https://b-flow.fr" className="mb-8">
            <img src={Logo} alt="Logo" className="h-16" />
          </a>

          {/* Informations de contact */}
          <div className="flex flex-col items-center space-y-6 text-white">
            <div className="flex items-center">
              <FaSearchLocation size={20} className="mr-4 flex-shrink-0" />
              <span className="text-sm">
                17 square de la chasse aux papillons,
                <br />
                91080 Evry-Courcouronnes
              </span>
            </div>

            <div className="flex items-center">
              <FaPhone size={20} className="mr-4 flex-shrink-0" />
              <span className="text-sm">+33 6 46 53 19 25</span>
            </div>

            <div className="flex items-center">
              <FaMailBulk size={20} className="mr-4 flex-shrink-0" />
              <span className="text-sm">contact@b-flow.fr</span>
            </div>
          </div>
        </div>

        {/* Séparateur */}
        <hr className="border-gray-700 my-8" />

        {/* Section inférieure */}
        <div className="flex flex-col items-center space-y-6">
          {/* Copyright */}
          <span className="text-sm text-white text-center">
            © 2024{" "}
            <a href="https://b-flow.fr" className="hover:underline">
              B-FLOW™
            </a>
            . Tous droits réservés.
          </span>

          {/* Réseaux sociaux */}
          <div className="flex space-x-6">
            <FaFacebook
              size={24}
              className="text-white cursor-pointer hover:text-gray-300 transition-colors"
              onClick={() =>
                window.open("https://www.facebook.com/BFlowFrance", "_blank")
              }
            />
            <FaLinkedin
              size={24}
              className="text-white cursor-pointer hover:text-gray-300 transition-colors"
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/b-flowfr/",
                  "_blank"
                )
              }
            />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
