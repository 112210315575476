import React from "react";
import PC from "../../assets/bureau.png";

const About = () => {
  return (
    <div name="about" className="w-full bg-gray-50 py-24">
      <div className="max-w-[1250px] mx-auto px-8">
        <div className="grid md:grid-cols-2 gap-16 items-center">
          <div className="flex flex-col space-y-12">
            <h1 className="md:text-5xl sm:text-4xl text-3xl font-bold text-gray-800">
              Adapté à tout type de chantier
            </h1>

            <div className="space-y-8">
              <div>
                <p className="text-2xl font-bold text-gray-800 mb-3">
                  Développement sur mesure
                </p>
                <p className="text-lg text-gray-600">
                  Fini ces logiciels trop rigides qui ne fonctionnent pas avec
                  vos activités. B-FLOW travaille avec vous pour produire les
                  outils qui répondent au mieux à vos attentes.
                </p>
              </div>

              <div>
                <p className="text-2xl font-bold text-gray-800 mb-3">
                  Support dédié
                </p>
                <p className="text-lg text-gray-600">
                  Nous mettons en place un support réactif qui saura répondre à
                  vos attentes.
                </p>
              </div>
            </div>
          </div>

          <div>
            <img
              className="w-full rounded-lg"
              src={PC}
              alt="Interface B-FLOW"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
