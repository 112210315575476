import React from "react";
import "./HeroStyles.css";
import {
  CloudUploadIcon,
  DatabaseIcon,
  PaperAirplaneIcon,
  LockClosedIcon,
} from "@heroicons/react/solid";

import MOCKUPS from "../../assets/MOCKUPS.png";
import { Button } from "@nextui-org/react";

const Hero = () => {
  return (
    <div
      name="home"
      className="w-full bg-white flex flex-col justify-between relative px-4"
    >
      <div className="grid md:grid-cols-2 max-w-[1250px] m-auto">
        <div className="flex flex-col justify-center md:items-start w-full px-2 py-4 sm:py-8">
          <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold">
            L'application de suivi de chantier et de pointage du personnel pour
          </h1>
          <h2 className="mt-0.5 text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-[#1D72FE]">
            vos chefs de chantier
          </h2>
          <div className="flex items-center">
            <p className="text-base sm:text-lg md:text-xl py-4 max-w-[90%] sm:max-w-[80%]">
              Collectez des données chantiers et générez vos rapports de manière
              fiable pour facturer vos clients au plus juste
            </p>
          </div>
          <div className="flex flex-col sm:flex-row gap-4 w-full sm:w-auto relative z-10">
            <Button
              size="lg"
              onClick={() => {
                window.open(
                  "https://calendly.com/bflow/rendez-vous-avec-equipe-bflow?month=2024-04",
                  "_blank"
                );
              }}
              shadow
              className="w-full sm:w-auto text-sm sm:text-base"
            >
              Être rappelé pour une démo
            </Button>
            <Button
              size="lg"
              color="#1D72FE"
              className="bg-gradient-to-tr from-[#d4e4ff] to-[#FFFFFF] text-[#1D72FE] w-full sm:w-auto text-sm sm:text-base"
              onClick={() => {
                window.open(
                  "https://calendly.com/bflow/rendez-vous-avec-equipe-bflow?month=2024-04",
                  "_blank"
                );
              }}
              shadow
            >
              Souscrire une version d'essai
            </Button>
          </div>
        </div>
        <div className="mt-8 md:mt-0">
          <img
            className="w-full h-auto object-contain px-2"
            src={MOCKUPS}
            alt="B-Flow interface mockup"
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
