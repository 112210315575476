import React from "react";
import styled from "styled-components";
import Burger from "./Burger";
import Logo from "../../assets/b-flow_logo_blue.png";
import { Link } from "react-scroll";

const Nav = styled.nav`
  width: 100%;
  height: 55px;
  padding: 0 0px;
  display: flex;
  justify-content: space-between;

  .logo_navbar {
    padding: 0px 0;
  }

  @media (max-width: 768px) {
    .logo_navbar {
      margin-left: auto;
      margin-right: auto;
      horizontal-align: middle;
    }

    .navbar_items {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const Navbar = () => {
  return (
    <Nav className="navbar_items items-center max-w-[1250px] m-auto my-8">
      <div className="logo_navbar">
        <img src={Logo} alt="Logo" className="w-16" />
      </div>

      {/* Menu Desktop - visible uniquement sur desktop */}
      <div className="hidden md:flex items-center space-x-4">
        <Link to="home" smooth={true} duration={500} className="cursor-pointer">
          Présentation
        </Link>
        <Link
          to="application"
          smooth={true}
          offset={-100}
          duration={800}
          className="cursor-pointer"
        >
          Application B-FLOW
        </Link>
        <Link
          to="contact"
          smooth={true}
          offset={-50}
          duration={1000}
          className="cursor-pointer"
        >
          Contact
        </Link>
      </div>

      {/* Menu Mobile - Burger uniquement */}
      <div className="md:hidden">
        <Burger />
      </div>
    </Nav>
  );
};

export default Navbar;
