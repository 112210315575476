import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import "./NavbarStyles.css";
import { Button } from "@nextui-org/react";

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: rgba(255, 255, 255, 0.95);
    position: fixed;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
    z-index: 99;

    li {
      padding: 18px 10px;
      width: 100%;
      text-align: center;
    }
  }
`;

const RightNav = ({ open, setOpen }) => {
  return (
    <Ul open={open}>
      <li>
        <Link
          to="home"
          smooth={true}
          duration={500}
          onClick={() => setOpen(false)}
          className="cursor-pointer"
        >
          Présentation
        </Link>
      </li>
      <li>
        <Link
          to="application"
          smooth={true}
          offset={-100}
          duration={800}
          onClick={() => setOpen(false)}
          className="cursor-pointer"
        >
          Application B-FLOW
        </Link>
      </li>
      <li>
        <Link
          to="contact"
          smooth={true}
          offset={-50}
          duration={1000}
          onClick={() => setOpen(false)}
          className="cursor-pointer"
        >
          Contact
        </Link>
      </li>
    </Ul>
  );
};

export default RightNav;
