import React from "react";
import "./ApplicationStyles.css";
import {
  ClockIcon,
  ClipboardCopyIcon,
  MicrophoneIcon,
} from "@heroicons/react/solid";

import supportImg from "../../assets/mockup.png";
import MOCKUP_3 from "../../assets/MOCKUP_3.png";

const Application = () => {
  return (
    <div name="application" className="w-full my-5 relative z-0">
      <div
        style={{ backgroundImage: `url(${MOCKUP_3})` }}
        className="bg-cover relative mix-blend-overlay object-cover bg-black/20"
      >
        <div className="w-full bg-slate-500/90 bg-scroll">
          <div className="max-w-[1250px] mx-auto text-white bg-scroll relative">
            <div className="px-4 py-20">
              <h3 className="text-6xl font-bold py-6 text-center space-y-4">
                <p className="bflow text-transparent bg-clip-text bg-gradient-to-r from-teal-200 to-cyan-500 mb-4">
                  L'application B-FLOW
                </p>
                <p className="text-5xl">votre compagnon de suivi de chantier</p>
              </h3>
              <p className="py-8 text-3xl text-center text-sky-50 font-light max-w-4xl mx-auto">
                Remontez les données de vos chantiers comme un simple coup de
                fil !
              </p>

              <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-8 gap-y-16 px-4 pt-16 sm:pt-20 text-black">
                <div className="backdrop-blur-xl bg-white/20 border border-white/10 rounded-2xl shadow-2xl transform hover:scale-105 transition-transform duration-300">
                  <div className="p-8">
                    <MicrophoneIcon className="w-16 p-4 bg-custom-blue text-white rounded-xl mt-[-4rem] shadow-lg" />
                    <h3 className="font-bold text-2xl my-6 text-white">
                      Simplifiez vos suivis
                    </h3>
                    <p className="text-gray-100 text-xl leading-relaxed">
                      Dictez vos notes sur le terrain, notre système les
                      convertit automatiquement en texte prêt à être utilisé
                    </p>
                  </div>
                </div>
                <div className="backdrop-blur-xl bg-white/20 border border-white/10 rounded-2xl shadow-2xl transform hover:scale-105 transition-transform duration-300 p-4 sm:p-8">
                  <div className="p-8">
                    <ClipboardCopyIcon className="w-16 p-4 bg-custom-blue text-white rounded-xl mt-[-4rem] shadow-lg" />
                    <h3 className="font-bold text-2xl my-6 text-white">
                      Rapports journaliers automatiques
                    </h3>
                    <p className="text-gray-100 text-xl leading-relaxed">
                      Générez vos rapports journaliers aisément grâce à vos
                      données de chantier
                    </p>
                  </div>
                </div>
                <div className="backdrop-blur-xl bg-white/20 border border-white/10 rounded-2xl shadow-2xl transform hover:scale-105 transition-transform duration-300 p-4 sm:p-8">
                  <div className="p-8">
                    <ClockIcon className="w-16 p-4 bg-custom-blue text-white rounded-xl mt-[-4rem] shadow-lg" />
                    <h3 className="font-bold text-2xl my-6 text-white">
                      Enrichissez votre historique
                    </h3>
                    <p className="text-gray-100 text-xl leading-relaxed">
                      En capturant des photos et des vidéos liées à vos
                      chantiers
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-8 mt-20 text-center max-w-5xl mx-auto">
                <p className="text-2xl text-white bg-gradient-to-r from-white/10 to-white/5 backdrop-blur-md py-6 px-12 rounded-2xl shadow-xl border border-white/10 hover:bg-white/20 transition-all duration-300">
                  Suivi de travaux au fil de l'eau - 1/2 journée de travail
                  gagnée par semaine
                </p>
                <p className="text-2xl text-white bg-gradient-to-r from-white/10 to-white/5 backdrop-blur-md py-6 px-12 rounded-2xl shadow-xl border border-white/10 hover:bg-white/20 transition-all duration-300">
                  Interopérabilité totale avec vos outils de gestion comptable
                  et RH
                </p>
                <p className="text-2xl text-white bg-gradient-to-r from-white/10 to-white/5 backdrop-blur-md py-6 px-12 rounded-2xl shadow-xl border border-white/10 hover:bg-white/20 transition-all duration-300">
                  Pointage rapide et facile à transmettre aux intérims
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Application;
